import './Content.css'
import { useState } from 'react'
import VideoBg from '../videos/video.mp4'
import { FaRegTimesCircle, FaRegQuestionCircle, FaBars } from 'react-icons/fa'
import Sharebuttons from './Sharebuttons'


const CountDown = ({isOpen, openSidebar, count}) => {
    const [showTooltip, setShowtooltip] = useState(true)

    const hideTooltip = () => {
        setShowtooltip(!showTooltip)
    }

    const urlShare = window.location.href

    return (
        <div className="video-container" onClick={isOpen ? openSidebar : null}>
            
            <video autoPlay loop muted>
                <source src={VideoBg} type="video/mp4" />
            </video>
            {showTooltip ? <div className="control-info">
                <FaRegTimesCircle className="close-tooltip" onClick={hideTooltip} />
                <h3>Something amazing will be here in just</h3>
                <p>{count}</p>
                <p className="center-text">Alukov Woldwide will be launched on 26th of October at 10 a.m. with the start of Aquanale 2021 in Cologne.</p>
            </div> : <FaRegQuestionCircle className="tooltip-toggle" onClick={hideTooltip} />}

            <Sharebuttons urlShare={urlShare} />
        </div>
    )
}

export default CountDown
