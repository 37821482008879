import {React} from 'react'
import VideoItem from '../components/VideoItem'
import LangModal from './LangModal'


const Sidebar = ({videa, toggleMenu, openSidebar}) => {

    return (
        <div style={{left: toggleMenu && '0px'}} className="sidebar">
            
            <LangModal toggleMenu={toggleMenu} closeSidebar={openSidebar} />
            {videa.map((video) => 
            <VideoItem key={video.id} video={video} openSidebar={openSidebar} />
            )}
        </div>
    )
}

export default Sidebar