import React from 'react'
import {
    FacebookShareButton, 
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    TelegramShareButton,
    TelegramIcon,
    WhatsappIcon,
    WhatsappShareButton,
    PinterestShareButton,
    PinterestIcon,
} from 'react-share'

const Sharebuttons = ({urlShare}) => {
    return (
        <div className="social-share">
            <FacebookShareButton url={urlShare}
                quote={"We will present Alukov Worldwide with the start of Aquanale fair in Cologne on 26th of October 2021 in 10am. Wait for the show to begin!"}
                hashtag="#alukov"
                className="facebook">
                 <FacebookIcon round="true" size={36} />
              </FacebookShareButton>

              <LinkedinShareButton url={urlShare}
                summary={"We will present Alukov Worldwide with the start of Aquanale fair in Cologne on 26th of October 2021 in 10am. Wait for the show to begin!"}
                title="Alukov Worldwide"
                className="linkedin">
                 <LinkedinIcon round="true" size={36} />
              </LinkedinShareButton>

              <TwitterShareButton url={urlShare}
                summary={"We will present Alukov Worldwide with the start of Aquanale fair in Cologne on 26th of October 2021 in 10am. Wait for the show to begin!"}
                title="Alukov Worldwide"
                className="twitter">
                 <TwitterIcon round="true" size={36} />
              </TwitterShareButton>

              <TelegramShareButton url={urlShare}
                summary={"We will present Alukov Worldwide with the start of Aquanale fair in Cologne on 26th of October 2021 in 10am. Wait for the show to begin!"}
                title="Alukov Worldwide"
                className="telegram">
                 <TelegramIcon round="true" size={36} />
              </TelegramShareButton>

              <WhatsappShareButton url={urlShare}
                separator={" - "}
                title="Alukov Worldwide"
                className="whatsup">
                 <WhatsappIcon round="true" size={36} />
              </WhatsappShareButton>
            </div>
    )
}

export default Sharebuttons
