import './Content.css'
import { useState, useRef, useEffect } from 'react'
import { FaRegTimesCircle, FaRegQuestionCircle, FaBars } from 'react-icons/fa'
import Sharebuttons from './Sharebuttons'

const Content = ({urlShare, isOpen, openSidebar}) => {
    const [showTooltip, setShowtooltip] = useState(true)

    const hideTooltip = () => {
        setShowtooltip(!showTooltip)
    }
    

    return (
        <div className="video-container" onClick={isOpen ? openSidebar : null}>
            <video autoPlay loop muted>
                <source src="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/video.mp4?alt=media&token=af6c7929-4cfe-4b1e-9a67-5269eaaac102" type="video/mp4" />
            </video>
            {showTooltip ? <div className="control-info">
                <FaRegTimesCircle className="close-tooltip" onClick={hideTooltip} />
                <h3>Welcome to Alukov-Worldwide</h3>
                <h6>Have you ever wondered what it means to have worldwide business experience? Wonder no more and see it for yourself. This project shows collection of the best pool, spa and patio enclosures applications from around the world from a different perspective.</h6>
                <p>To navigate the website click the <FaBars /> in top-right corner and select the enclosure application you want to see. For best experience on mobile please rotate your device.</p>
            </div> : <FaRegQuestionCircle className="tooltip-toggle" onClick={hideTooltip} />}
            <Sharebuttons urlShare={urlShare} />
        </div>
    )
}

export default Content
