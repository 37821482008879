import React from 'react'
import './LangItem.css'
import Image from 'react-bootstrap/Image'

export const LangItem = ({region}) => {
    return (
        <>
             <a href={region.link} target="_blank" rel="noreferrer">
             <Image src={process.env.PUBLIC_URL + region.flag} thumbnail />
             </a>
        </>
    )
}
