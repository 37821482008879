import './App.css';
import Header from './components/Header'
import Content from './components/Content'
import Sidebar from './components/Sidebar'
import {useState, useEffect} from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.min.css'
import CountDown from './components/Countdown'
import VideoView from './components/VideoView'
import ReactGA from 'react-ga'

ReactGA.initialize('UA-208322079-1')

function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  })

const [videa, setVidea] = useState([
  {
    id: 1,
    thumb: 'images/factory.png',
    title: 'Orel, Czechia',
    location: 'Factory',
    url: '/factory'
  },
  {
    id: 2,
    thumb: 'images/freiberg.png',
    title: 'Freiberg, Germany',
    location: 'Pool enclosure Olympic',
    url: '/freiberg'
  },
  {
    id: 3,
    thumb: 'images/aartselaar.png',
    title: 'Aartselaar, Belgium',
    location: 'Pool enclosure Azure',
    url: '/aartselaar'
  },
  {
    id: 4,
    thumb: 'images/chengdu.png',
    title: 'Chengdu, China',
    location: 'HORECA enclosure Orlando',
    url: '/chengdu'
  },
  {
    id: 5,
    thumb: 'images/essen.png',
    title: 'Essen, Germany',
    location: 'Pool enclosure Venezia',
    url: '/essen'
  }, 
  {
    id: 6,
    thumb: 'images/eoseongjeon.png',
    title: 'Eoseongjeon-ri, South Korea',
    location: 'Hotel pool enclosure Ravena',
    url: '/eoseongjeon-ri'
  }, 
  {
    id: 7,
    thumb: 'images/purkersdorf.png',
    title: 'Purkersdorf, Austria',
    location: 'Pool enclosure Oceanic High',
    url: '/purkersdorf'
  }, 
  {
    id: 8,
    thumb: 'images/reims.png',
    title: 'Reims, France',
    location: 'Pool enclosure Azure',
    url: '/reims'
  }, 
  {
    id: 9,
    thumb: 'images/wangi.png',
    title: 'Wangi Wangi, Australia',
    location: 'Pool enclosure Laguna',
    url: '/wangi-wangi'
  }, 
  {
    id: 10,
    thumb: 'images/beckov.png',
    title: 'Beckov, Slovakia',
    location: 'Pool enclosure Style',
    url: '/beckov'
  }, 
  {
    id: 11,
    thumb: 'images/etterbeek.png',
    title: 'Etterbeek, Belgium',
    location: 'HORECA patio enclosure Corso',
    url: '/etterbeek'
  }, 
  {
    id: 12,
    thumb: 'images/florida.png',
    title: 'Florida, USA',
    location: 'Pool enclosure Viva',
    url: '/florida'
  }, 
  {
    id: 13,
    thumb: 'images/covasna.png',
    title: 'Covasna, Romania',
    location: 'Pool enclosure Ravena',
    url: '/covasna'
  }, 
  {
    id: 14,
    thumb: 'images/bergsee.png',
    title: 'Bergsee, Switzerland',
    location: 'Spa dome Orlando',
    url: '/bergsee'
  }, 
  {
    id: 15,
    thumb: 'images/tasmania.png',
    title: 'Tasmania, Australia',
    location: 'Pool enclosure Omega',
    url: '/tasmania'
  }, 
  {
    id: 16,
    thumb: 'images/liptakow.png',
    title: 'Białka Tatrzańska, Poland',
    location: 'Pool enclosure Ravena',
    url: '/liptakow'
  }, 
  {
    id: 17,
    thumb: 'images/cassel.png',
    title: 'Cassel, France',
    location: 'Pool enclosure Azure',
    url: '/cassel'
  }, 
  {
    id: 18,
    thumb: 'images/iceland.png',
    title: 'Reykjavík, Iceland',
    location: 'Orient',
    url: '/reykjavik'
  }, 
  {
    id: 19,
    thumb: 'images/jezerka.png',
    title: 'Seč dam, Czechia',
    location: 'Hotel pool enclosure Elegant',
    url: '/jezerka'
  }, 
  {
    id: 20,
    thumb: 'images/sebes.png',
    title: 'Sebes, Romania',
    location: 'Pool enclosure Oceanic low',
    url: '/sebes'
  },
  {
  id: 21,
  thumb: 'images/london.png',
  title: 'London, United Kingdom',
  location: 'Pool enclosure Vision',
  url: '/london'
  },
  {
  id: 22,
  thumb: 'images/glondon.png',
  title: 'Greater London, United Kingdom',
  location: 'Pool enclosure Venezia',
  url: '/greaterlondon'
  }, 
  {
    id: 23,
    thumb: 'images/Austria.png',
    title: 'Waidmannsfeld, Austria',
    location: 'Pool enclosure Omega',
    url: '/waidmannsfeld'
  },
  {
    id: 24,
    thumb: 'images/pravonin.png',
    title: 'Pravonin, Czechia',
    location: 'Pool enclosure Vision',
    url: '/pravonin'
  },
  {
    id: 25,
    thumb: 'images/wairarapa.png',
    title: 'Wairarapa, New Zealand',
    location: 'Pool enclosure Ravena',
    url: '/wairarapa'
  },
])

  const [toggleMenu, setToggleMenu] = useState(false)
  const [burger, setBurger] = useState(false)


  const openSidebar = () => {
    setToggleMenu(!toggleMenu)
    setBurger(!burger)
  }

  
  const calculateTimeLeft = () => {

    const difference = +new Date("2021-10-26T10:00:00") - +new Date()
    
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60)
      };
    }

    return timeLeft;
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  
useEffect(() => {
  const timer = setTimeout(() => {
    setTimeLeft(calculateTimeLeft());
    urlShare = window.location.href
  }, 1000);
  return () => clearTimeout(timer);
});

const timerComponents = [];

Object.keys(timeLeft).forEach((interval) => {
  if (!timeLeft[interval]) {
    return;
  }

  timerComponents.push(
    <span className="interval" key={interval}>
      <span className="time">{timeLeft[interval]}</span> {interval}{"  "}
    </span>
  );
});


let urlShare = window.location.href


  return (
    <Router>
    <div className="">
      <Header closeMenuLogo={toggleMenu} toggleMenu={openSidebar} burger={burger} />
      <div className="main-content">
      <Sidebar toggleMenu={toggleMenu} openSidebar={openSidebar} videa={videa} />
      <Switch>
      <Route path="/factory">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/alukov%20factory.mp4?alt=media&token=5c6b7eab-8509-41b7-87f4-64d93c70e2e5" />
        </Route>
        <Route path="/etterbeek">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/etterbeek.mov?alt=media&token=69ca4a62-9fc0-4ebc-8168-0b344f005de1" />
        </Route>
        <Route path="/eoseongjeon-ri">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/eoseon.mov?alt=media&token=dfdf8125-b1b7-4d40-9d84-f1902aecd870" />
        </Route>
        <Route path="/aartselaar">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/aartselaar.mov?alt=media&token=58728d12-a154-400c-97a0-2aa11d99d79f" />
        </Route>
        <Route path="/beckov">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/beckov.mov?alt=media&token=ff1976c5-7f34-45ca-9d3c-3b08a9a530e2" />
        </Route>
        <Route path="/bergsee">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/bergsee.mov?alt=media&token=a2fb1e08-b4ac-4453-b041-4302e09a8ba2" />
        </Route>
        <Route path="/cassel">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/cassele.mov?alt=media&token=d23a9894-05cd-4d23-aa9b-29488aa7e867" />
        </Route>
        <Route path="/chengdu">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/chengdu.mov?alt=media&token=6cfa1752-06b3-485f-a780-0df0b1c70822" />
        </Route>
        <Route path="/covasna">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/covasna.mov?alt=media&token=66a619f0-fa49-4654-9247-fd24dca10f60" />
        </Route>
        <Route path="/essen">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/essen.mov?alt=media&token=030785fd-0b17-406f-afb5-f3c2fc49c1be" />
        </Route>
        <Route path="/florida">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/florida.mov?alt=media&token=5ea78628-5981-443e-9ce5-5d5c6d951d2a" />
        </Route>
        <Route path="/freiberg">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/freiberg.mov?alt=media&token=4acc9c8c-689f-4d0b-a706-cac2e1706f2f" />
        </Route>
        <Route path="/jezerka">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/jezerka.mov?alt=media&token=7464727c-6cec-4fb4-988a-93cb6b98c4e9" />
        </Route>
        <Route path="/liptakow">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/liptakow.mov?alt=media&token=94183c32-45f9-400f-82e3-7282364bcba9" />
        </Route>
        <Route path="/purkersdorf">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/purkersdorf.mov?alt=media&token=6ff1de10-a724-4eb7-a675-b4cc10459e7f" />
        </Route>
        <Route path="/reims">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/reims.mov?alt=media&token=1b0701a4-fe41-422c-bb60-3a66b5fa0a9d" />
        </Route>
        <Route path="/reykjavik">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/reykjav%C3%ADk.mov?alt=media&token=b5e18bca-c409-42b4-8f5c-80e4ca77ffc1" />
        </Route>
        <Route path="/sebes">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/sebes.mov?alt=media&token=6275c12c-e6a4-49b3-9969-965cf8629111" />
        </Route>
        <Route path="/tasmania">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/tasmania.mov?alt=media&token=dbae2f98-6708-45ae-a16c-90b359eb7b11" />
        </Route>
        <Route path="/wangi-wangi">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/wangi.mov?alt=media&token=dc025b73-eb38-44b6-ad50-97b40a0041d7" />
        </Route>
        <Route path="/london">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/london.mp4?alt=media&token=31b1c180-437d-4969-90cb-23f87ec2a7c2" />
        </Route>
        <Route path="/greaterlondon">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/greaterlondon.mp4?alt=media&token=e1a9bd32-9570-4718-8c34-1a672bb0023c" />
        </Route>
        <Route path="/waidmannsfeld">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/waidmannsfeld.mp4?alt=media&token=1e9ba99c-5bb9-4013-897a-c1dda0db99b6" />
        </Route>
        <Route path="/pravonin">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/pravonin.mp4?alt=media&token=391920f8-03d2-4152-8ec1-2d3810f784bc" />
        </Route>
        <Route path="/wairarapa">
        <VideoView videoUrl="https://firebasestorage.googleapis.com/v0/b/alukov-worldwide.appspot.com/o/wairarapa.mp4?alt=media&token=b3e724be-9e4d-4ab3-8e30-988e009ba0d5" />
        </Route>
        <Route path="/countdown">
          <CountDown count={timerComponents} />
        </Route>
        <Route path="/">
          <Content urlShare={urlShare} count={timerComponents} isOpen={toggleMenu} openSidebar={openSidebar} />
        </Route>
      </Switch>
      </div>
    </div>
      
    </Router>
  );
}

export default App;
