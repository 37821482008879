import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"


const VideoItem = ({video, openSidebar}) => {

    return (
        <Link video={video} to={video.url} style={{ textDecoration: 'none' }}>
        <div>
            <div id={video.id} className="video-item" onClick={openSidebar}>
                <div className="video-thumbnail">
                    <img src={video.thumb} alt={video.title} />
                </div>
                <div className="video-title">
                    <h4>{video.title}</h4>
                    <h6>{video.location}</h6>
                </div>
            </div>
        </div>
        </Link>
    )
}

export default VideoItem
