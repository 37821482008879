import { React, useState } from 'react'
import { Offcanvas } from 'react-bootstrap'
import { LangItem } from './LangItem';
import './LangModal.css'

const LangModal = ({closeSidebar}) => {
        const [show, setShow] = useState(false);

        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);

        const [regions, setRegions] = useState([
            {
            flag: '/images/de_100x150.gif',
            title: 'Alukov DE',
            link: 'https://www.alukov.de/',
            id: 2
            },
                        {
            flag: '/images/at_100x150.gif',
            title: 'Alukov AT',
            link: 'https://www.alukov.at/',
            id: 3
            },
                        {
            flag: '/images/ch_100x150.gif',
            title: 'Alukov CH',
            link: 'https://www.alukov.ch/',
            id: 4
            },
            {
            flag: '/images/cz_100x150.gif',
            title: 'Alukov CZ',
            link: 'https://www.alukov.cz/',
            id: 17
            },
            {
            flag: '/images/gb.gif',
            title: 'Alukov UK',
            link: 'https://www.alukov.co.uk/',
            id: 1
            },
                        {
            flag: '/images/us_100x150.gif',
            title: 'Alukov US',
            link: 'https://www.sunrooms-enclosures.com/',
            id: 5
            },
                        {
            flag: '/images/it_100x150.gif',
            title: 'Alukov IT',
            link: 'https://www.coperturepiscine.it/',
            id: 6
            },
                        {
            flag: '/images/pl_100x150.gif',
            title: 'Alukov PL',
            link: 'https://www.alukov.pl/',
            id: 7
            },
            {
            flag: '/images/se_100x150.gif',
            title: 'Alukov SE',
            link: 'https://www.termatec.se/',
            id: 8
            },
            {
            flag: '/images/fr_100x150.gif',
            title: 'Alukov FR',
            link: 'https://www.alukov.fr/',
            id: 9
            },
            {
            flag: '/images/nz_100x150.gif',
            title: 'Alukov NZ',
            link: 'https://www.poolenclosures.co.nz/',
            id: 10
            },
            {
            flag: '/images/lt_100x150.gif',
            title: 'Alukov LT',
            link: 'https://www.baseinu-uzdangos.lt/',
            id: 11
            },
            {
            flag: '/images/be_100x150.gif',
            title: 'Alukov BE',
            link: 'https://www.axess-europe.com/',
            id: 12
            },
            {
            flag: '/images/ro_100x150.gif',
            title: 'Alukov RO',
            link: 'https://www.alukov.ro/',
            id: 13
            },
            {
            flag: '/images/hu_100x150.gif',
            title: 'Alukov HU',
            link: 'https://www.alukov.hu/',
            id: 14
            },
            {
            flag: '/images/kr_100x150.gif',
            title: 'Alukov KR',
            link: 'https://www.alukov.co.kr/',
            id: 15
            },
            {
            flag: '/images/hr.png',
            title: 'Alukov HR',
            link: 'https://www.alukov.hr/',
            id: 16
            },
        ])
      
        return (
          <>
            <div className="text-center">
            <button onClick={() => {handleShow(); closeSidebar();}} className="myBut">Visit our website</button>
        </div>
            <Offcanvas className="lang-modal" placement='top' show={show} onHide={handleClose}>
              <Offcanvas.Header closeVariant="white" closeButton>
              <Offcanvas.Title className="justify-content-center">Choose your region please</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                {regions.map((region) => 
                <LangItem key={region.id} region={region} />
                )}
              </Offcanvas.Body>
            </Offcanvas>
          </>
        );
}

export default LangModal
