import React from 'react'
import { useEffect } from 'react'
import ReactGA from 'react-ga'

const VideoView = ({video, videoUrl}) => {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    })

    return (
        <div className="video-container">
            <video src={videoUrl} autoPlay loop controls>
                
            </video>
        </div>
     
    )
}

export default VideoView
