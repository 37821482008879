import "./Header.css"
import { FaBars, FaTimes } from 'react-icons/fa'
import { Link } from "react-router-dom"

const Header = ({toggleMenu, burger, closeMenuLogo}) => {

    return (
        <>
            <nav className="navbar">
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand" onClick={closeMenuLogo ? toggleMenu : null }><img src="images/logo.png"></img></Link>
                    <button className="hamburger" onClick={ toggleMenu } >
                         {burger ? <FaTimes className="toggle-icon" /> : <FaBars className="toggle-icon" />}
                    </button>
                </div>
            </nav>
        </>
    )
}

export default Header
